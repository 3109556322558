.App {
  min-height: 100vh;
  background-color: #fff;
  color: #282c34;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.Home-logo {
  pointer-events: none;
  margin-bottom: 10px;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}